.App {
  padding: 10px;
  text-align: center;
}

.image-div {
  width: 100%;
  margin-top: 5%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.image-el {
  width: 250px;
  height: 250px;
  @media (min-width: 600px) {
    width: 400px;
    height: 400px;
  }
}

.invisible-button {
  display: none;
}

.button-btn {
  color: white;
  background-color: rgb(21, 20, 20);
  padding: 10px;
  cursor: pointer;
  margin-left: 15px;
  border: "none";
  outline: "none";
  border-radius: 4px;
}

.button-btn:hover {
  background-color: rgb(79, 76, 76);
}
